import React, { Component } from 'react';
import moment from 'moment';
import LoadingScreen from '../../util_components/loading_screen';
import EditRoadMapPop from './edit_roadmap_pop';
import urls from '../../../urls';
import { post_api } from '../../../redux/api_funcs';
import { withRouter } from '../../../utils/router';
import { sendEventToClarity } from '../../../util_functions';

class EditRoadmap extends Component {
  state = {
    loading: false,
    questions: [],
    no_of_sessions: 0,
    values_changed: false,
    selected_index: 0,
    selected_answer: '',
    show_answer_popup: false,
    pop_prevent_user_info: false
  };

  answerRefs = [];

  componentDidMount() {
    try {
      const { roadmap } = this.props.router.location.state;
      if (roadmap) {
        this.setState({
          roadmap,
          questions: roadmap.roadmap_questions,
          no_of_sessions: roadmap.no_of_sessions
        });
      } else this.props.router.navigate('/');
    } catch (e) {
      this.props.router.navigate('/');
    }
    sendEventToClarity();
  }

  getAnsweredQuestionCount = () => this.state.questions.filter((item) => !!item.answer).length;

  renderRoadMapQuestion = (item, index) => {
    if (item.api_param == 'no_of_sessions') {
      return this.renderMonthsItem(item, index);
    }
    return this.renderRoadMapQuestion_sp(item, index);
  };

  onEditPressed = (selectedItem, selectedIndex) => {
    this.setState({
      selected_index: selectedIndex,
      show_answer_popup: true,
      selected_answer: selectedItem.answer
    });
  };

  renderRoadMapQuestion_sp = (item, index) => (
    <>
      {this.state.roadmap.no_of_months === 0 ? (
        <li className={item.answer ? 'rm_green_yes' : null}>
          <div className="rm-edit-ques-cont">
            {!!item.answer && <span className="rm_green_right" />}
            <div className="edit-ques">{item.question}</div>
            {item.answer != '' ? (
              <div className="edit-ans" ref={(answerRef) => (this.answerRefs[index] = answerRef)}>
                {!!item.answer && item.answer.length > 100 ? (
                  !this.state.is_read_more ? (
                    <>
                      {item.answer.slice(0, 100)}
                      <span className="yj-read-more" onClick={this.toggle_read_more}>
                        ... Read more
                      </span>
                    </>
                  ) : (
                    <>
                      {item.answer}
                      <span className="yj-read-more" onClick={this.toggle_read_more}>
                        Read less
                      </span>
                    </>
                  )
                ) : (
                  item.answer
                )}
                <button className="btn rm_edit_btn" onClick={() => this.onEditPressed(item, index)}>
                  Edit
                </button>
              </div>
            ) : (
              <button
                className="btn add-rm-ans-btn"
                onClick={() => this.onEditPressed(item, index)}
              >
                Add answer
              </button>
            )}
          </div>
        </li>
      ) : null}
    </>
  );

  renderMonthsItem = (item, index) => {
    const months = [
      { title: '5 Sessions', value: 5 },
      { title: '10 Sessions', value: 10 },
      { title: '20 Sessions', value: 20 },
      { title: '30 Sessions', value: 30 },
      { title: '40 Sessions', value: 40 },
      { title: '50 Sessions', value: 50 }
    ];
    return (
      <li className={item.answer != '' ? 'rm_green_yes' : null} key={index}>
        <div className="rm-edit-ques-cont">
          {item.answer != '' && <span className="rm_green_right" />}
          <div className="edit-ques">{item.question}</div>
          <div className="edit-ans edit_rm_btns_cont">
            {months.map((obj) => this.renderSelectedMonthItem(obj, item, index))}
          </div>
        </div>
      </li>
    );
  };

  renderSelectedMonthItem(obj, item, index) {
    const { no_of_sessions } = this.state;
    const { title } = obj;
    const isSelectedMonth = obj.value === no_of_sessions;
    return (
      <button
        key={obj.title}
        className={`btn edit_rm_months ${isSelectedMonth ? 'sel' : ''}`}
        onClick={() => this.sel_this_month(obj, index)}
      >
        {title}
      </button>
    );
  }

  sel_this_month = (obj, index) =>
    this.setState({ loading: true }, () => {
      const url = urls.update_roadmap;
      const no_of_sessions = obj.value.toString();
      const payload = {
        roadmap_uuid: this.state.roadmap.uuid,
        no_of_sessions
      };
      post_api(url, payload, true)
        .then(() =>
          this.setState(
            {
              no_of_sessions: parseInt(no_of_sessions),
              loading: false,
              values_changed: true,
              selected_index: index
            },
            () => this.updateAnswerValue(obj.title)
          )
        )
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });
    });

  close_popup = () => this.setState({ show_answer_popup: false });

  updateAnswerValue = (answer) => {
    const { questions } = this.state;
    const { selected_index } = this.state;
    questions[selected_index].answer = answer;
    this.setState({
      questions,
      values_changed: true
    });
  };

  goBack = () => this.props.router.navigate(-1);

  goBackCondition = () => {
    const questionAnswered = this.getAnsweredQuestionCount();
    const totalQuestions = this.state.questions.length;
    const remaining_ans = (totalQuestions - questionAnswered).toString();
    if (questionAnswered == totalQuestions) {
      this.goBack();
    } else {
      this.setState({
        pop_prevent_user_info: true,
        remaining_ans
      });
    }
  };

  closePop_prevent = () => {
    this.setState({
      pop_prevent_user_info: false
    });
  };

  render() {
    const { questions, loading, show_answer_popup, selected_index, pop_prevent_user_info } =
      this.state;
    return (
      <div className="m-card-body">
        {loading ? <LoadingScreen /> : null}
        <div className="m-card">
          <div className="m-card-header">
            <div className="m-card-head-wrap">
              <button className="btn btn-mcard-back" onClick={this.goBackCondition}>
                <img
                  alt="back-arrow"
                  src="https://s3.amazonaws.com/images.myyogateacher.com/icons/back-arrow-white1.svg"
                />
              </button>

              <h1>
                {this.getAnsweredQuestionCount()} / {questions.length} completed
              </h1>
              <p>Session questions - It helps to create student roadmap</p>
            </div>
          </div>
          <div className="rm-c-edit-cont">
            <ul className="rm-edit-ques-ul">
              {questions.map((item, index) => this.renderRoadMapQuestion(item, index))}
            </ul>
          </div>
        </div>

        {show_answer_popup ? (
          <EditRoadMapPop
            onClose={this.close_popup}
            ques_item={questions[selected_index]}
            updateMySessionData={this.close_popup}
            updateAnswerValue={this.updateAnswerValue}
          />
        ) : null}
        {pop_prevent_user_info ? (
          <div className="pop_prevent_pop_outer">
            <div className="pop_prevent_in">
              {this.state.remaining_ans} more roadmap questions remaining.
              <div className="pop_prevent_btn_row">
                <button className="p_prev_skip_btn" onClick={this.goBack}>
                  Skip
                </button>
                <button className="p_prev_ok_btn" onClick={this.closePop_prevent}>
                  Okay
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const EditRoadmapWithRouter = withRouter(EditRoadmap);

export default EditRoadmapWithRouter;
